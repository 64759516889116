
.companyfiles {
	margin: 2rem 0;
	//tr > *:first-child { padding-left: 0;}
	tr > *:last-child { padding-right: 0;}
}

.bedrijfslogo {
	border: none;
	padding: $column-gutter;
}

.commodities {
	.inline-list {
		font-size: inherit;
		margin-bottom: 0;
		li {
			margin-left: 2px;
		}
	}
}

@media #{$medium-up} {
	.businessfilter.company-profile-filter {
		.namefilter .row {
			padding-right: 92px;
			input {
				margin: 0;
			}
			.buttons {
				width: 92px;
			}
		}
	}
	.commodities {
		tr th:first-child {
			font-size: 0.86667rem;
			font-weight: lighter;
			width: 39%;
		}
	}
}


