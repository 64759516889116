.pageheader {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	width: 100%;
}

.background-darken:before {
	background-color: rgba($color-dark, 0.40);
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.article-meta {
	.inline-list {
		li {
			&:after {
				content: ',';
				margin-left: 2px;
				margin-right: 8px;
				width: auto;
			}
		}
	}
}
	.flexbox {
		.meta-inner {
			display: flex;
			.left {
				display: flex;
			}
			time {
				white-space: nowrap;
			}
		}
		
	}


@media #{$medium-up} {
	.pageheader {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		position: relative;
		min-height: 200px;	
		width: 100%;
		h1, .intro *, .article-meta {
			color: #fff;
		}
		> .row:first-child {
			text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.50);
		}
		.intro {
			* {
				font-family: $font-family-serif;
				font-size: rem-calc(20);
				font-weight: normal;
			}
			>*:last-child {
				margin-bottom: rem-calc(40);
			}
		}
		.item-block {
			background-color: rgba(#000, 0.8);
			color: #fff;
			margin: 2*$column-gutter 0;
			padding: $column-gutter;
			max-width: 100%;

			.label {
				top: -$column-gutter;
				left: -$column-gutter;
			}
			* {
				text-shadow: none;
			}
			*:not(.label), .intro * {
				color: #fff;
			}
			h1 {
				font-size: 1.5rem;
				margin: -1rem 0 .5rem;
				color: #fff;
			}
			img {
				max-width: 100%;
			}
			.intro {
				* {
					font-size: 1rem;
				}
				width: 100%;
			}
			.button {
				border-color: #fff;
				margin: 0;
			}
		}
	}
	.button-line {
		background: none;
		border: 1px solid #fff;
		text-transform: uppercase;
		font-weight: bold;
		&:hover {
			background: rgba(0,0,0,0.2);
		}
	}
	
	.article-meta {
		font-weight: 400;
		margin-top: 2.5rem;
		.left {
			//padding-left: 40px;
			margin: 0 0 2rem 0;
			position: relative;
		}
		[class*="icon"] {
			position: absolute;
			left: 0;
		}
		.inline-list {
			margin: 0;
			max-width: 690px;
			li {
				a {
					color: #fff;
					&:hover {
						color: lighten($primary-color, 20%);
					}
				}
				margin: 0;
				> * {
					display: inline-block;
				}
				&:after {
					content: ',';
					margin-left: 2px;
					margin-right: 8px;
					width: auto;
				}
				&:last-child:after {
					display: none;
				}
			}
			
		}
	}
}

@media#{$small-only} {
	.pageheader {
		background-size: 640px 140px;
		background-position: center top;
		background-color: #fff;
		height: auto;
		min-height: 0;
		padding-top: 130px;
		> .row {
			background-color: #fff;
		}
		&:before {
			display: none;
		}
		.article-tags {
			.inline-list { 
				margin: 0;
				li {
					margin-left: 0;
				}
			}
		}
		h1 {
			margin-top: 20px;
		}
		.article-meta {
			.left {
				margin-bottom: rem-calc(10);
			}
		}
		.intro > *:last-child {
			margin-bottom: 0;
		}
	}
	
	.flexbox {
		.meta-inner {
			display: block;
		}
	}
}