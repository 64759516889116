.block {
	display: block;
	position: relative;
	&__heading {
		margin-top: .5rem;
	}
	&__introduction {
		* {
			line-height: 1.2;
		}
		p:last-child {
			margin-bottom: 0;
		}
	}
	p {
		color: $body-font-color;
	}

	&--split {
		margin: 0 0 2rem 0;
		.block__content {
			border-top: 18px solid #f3f2f2;
		}
		.label {
			float: left;
			height: 18px;
			left: 0;
			margin-top: -18px;
		}
	}
	&--expanded {
		.block__introduction {
			background-color: rgba(243, 242, 242, 0.8);
			bottom: 1rem;
			left: 1rem;
			max-width: 506px;
			padding: 1rem;
			right: 1rem;
			text-transform: none;
		}
	}
	&--categoryless.block-content-item {
		border-top: 0;
		h1.heading-h6 {
			background-color: $white;
			padding-right: 0;
			padding-left: 0;
			width: 100%;
		}
	}
	&--slider {
		.block-slider__thumbs {
			margin: 1rem -.5rem 2rem;
			.slide {
				margin: 0 .5rem;
				.thumb-grayscale,
				.thumb-color {
					display: none;
				}
				&:not(.slick-current) {
					.thumb-grayscale {
						display: block;
					}
				}
				&.slick-current {
					.thumb-color {
						display: block;
					}
				}
			}
			strong {
				display: block;
				font-size: rem-calc(14);
				line-height: 1.2;
				padding-top: .5rem;
			}
		}
	}
}


.thumb-grayscale img {
	filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
	filter: gray; /* IE6-9 */
	-webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

.contentcontainer .block img:not(.links):not(.rechts) {
	margin: 0;
	width: 100%;
}

@media #{$medium-up} {
	.block {
		margin: 0 2rem 2rem 0;
		&--expanded {
			.block__introduction {
				position: absolute;
			}
		}
	}
}