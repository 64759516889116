.js .businessfilter {
	select {
		display: none;
	}
}
.businessfilter {
	label {
		font-size: 12px;
		width: auto;
	}
	fieldset {
		border: none;
		padding: 0 20px;
		margin: 0;
		.radiobuttonlist {
			padding: 0;
			input {
				width: auto;
			}
			span span {
				display: inline-block;
				padding: 0 0 0 20px;
				label {
					margin-left: 6px;
				}
			}
		}
		
		.selectboxit-container {
			width: 100%;
			.selectboxit {
				width: 100% !important;
			}
		}
	}
	.subfilters {
		padding: 0;
	}

	.selectboxit-text {
		max-width: 100px;
		overflow: hidden;
		display: block;
		text-overflow: ellipsis;
	}
	&.readonly {
		margin-bottom: 0;
		p {
		    background-color: #f7f7f7;
		    border: 1px solid #c3c3c3;
		    display: inline-block;
		    width: 100%;
		    padding: .5em;

			color: #dfdfdf;
			margin: 0;
			span {
				color: #9d9d9d;
				padding: 0 10px;
			}

		}
		&+ .businessplatformcompany {
			border-top: none;
		}
		img {
			vertical-align: middle;
			margin: 0 6px 0 0;
		}
	}
}

.namefilter {
	input[type=reset] {
		background-color: #ccc;
		border-color: #909090;
		cursor: pointer;
		color: #666;
		padding-right: 15px;
		padding-left: 15px;
		margin-left: 5px;
		&:hover {
			color: #000;
		}
	}
}

.selectboxit-option-icon-url {
	background-size: auto;
}
#content {
	.selectboxit-container {
		ul {
			margin: 0;
		}
	}
}

/* Business list */

.businessplatformbusinesslist {
	padding: 0;

	li {
		list-style: none;
		&:last-child .businessplatformcompany {
			border-bottom: 1px solid #eee;
			margin-bottom: 50px;
		}
		.businessplatformcompany {
			padding: 50px 0;
		}
		&:first-child article {
			border-top: none;
		}
	}
}
/* Businesses */
.businessplatformcompany {
	.cleanlist {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
		}
	}
	border-top: 1px solid #eee;
	padding: 50px 0 0 0;
	.column {
		min-height: 1px;
	}
	.logo {
		display: inline-block;
		margin-bottom: 25px;
	}
	img {
		max-width: 100%;
		height: auto;
	}
	.heading-h2 {
		margin-top: 0;
		text-transform: none;
		a {
			color: #000;
		}
	}
	.person {
		padding: 3px 0 12px;
		img {
			margin: -3px 17px 0 0;
		}
		strong {
			margin: 4px 0;
		}
		p {
			margin: 10px 0 0 0;
		}
	}
	address {
		line-height: 1.25em;
		margin: 0 0 9px 0;
	}
	.extracontent {
		padding-top: 20px;
	}
}


#businessplatformcontainer {
	.pager {
		margin-bottom: 30px;
	}
}

/* Strong overrides */
.contentcontainer {
	.businessplatformbusinesslist {
		margin: 0;
		h1, h2 { 
			background: none;
			clear: both;
			font-size: 15px; 
			padding: 0;
			margin: 0 0 7px 0;
		}
	}
	.businessplatformcompany {
		.person {
			p {
				font-size: 12px;
			}
		}
		.contactinfo {
			margin-bottom: 11px;
		}
	}
}

@media#{$medium-up} {
	.search .namefilter {
		.buttons {
			width: 195px;
			input[type=submit] {
				width: 120px;
			}
			input[type=reset] {
				width: 66px;
			}
		}
	}
}
@media#{$small-only} {
	.page-businessplatform {
		.pageheader {
			padding: 0;
			.row {
				background-color: transparent;
				* {
					color: #fff;
				}
			}
		}
	}
	.search.businessfilter {
		fieldset {
			padding-bottom: 10px;
			&:last-child { padding-bottom: 0; }
		}
		.namefilter  {
			.row {
				padding: 0 !important;
			}
			.searchfield {
				float: none;
				clear: both;
				width: 100%;
				input {
					border-right: 1px solid #cccccc;
				}
			}
			.buttons {
				float: none;
				margin-top: 10px;
				padding-right: 80px;
				width: 100%;
				position: relative;
				input {
					&[type=reset] {
						position: absolute;
						width: 70px;
						right: 0;
						top: 0;
						text-align: center;
					}
				}
			}
		}
	}
	
	.selectboxit-container {
		margin: 0 0 10px 0;
		width: 100%;
		.selectboxit-options {
			min-width: 0 !important;
			max-width: 320px !important;
			.selectboxit-option-anchor { padding-right: 15px; }
		}
		.selectboxit-text {
			max-width: 80% !important;
		}
	}
	.row.business-selects {
		margin: 0 -5px 5px;
		.column {
			padding: 0 5px;
		}
	}
}
@media screen and (-webkit-min-device-pixel-ratio:0) {

	.search {
		input[type=text] { font-size: 16px !important; }
	}
	.js .business-selects select {
		font-size: 50px !important;
	}
}
