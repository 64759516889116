/* Streamer */
.ticker, .tickercontainer, .scrollwrapper {
	background-color: $color-dark;
	height: 24px;
	overflow: hidden;
}
.scrollWrapper {
	width: 100%; //width of the original container in which the ticker appears
	float: left; //float same as the direction used
	overflow: hidden;
	li {
		float: left;
		list-style: none;
		padding: 0 80px 0 0;
	}
	.mask {
	    overflow: hidden;
	}
	ul.scrollableArea {
		-webkit-transition: all 0s linear;
		-moz-transition: all 0s linear;
		-o-transition: all 0s linear;
		transition: all 0s linear;
		position: relative;
		margin: 0;
		.announce {
			background: url(../images/supertinywatermark_transparent.png) no-repeat left 2px;
			padding-left: 34px;
			a {
				background: url(../images/supertinywatermark_inverted.png) no-repeat right 2px;
				padding: 0 34px 0 0;
			}
		}
	}
	a, .announce a {
		color: rgba(255,255,255,0.7);
		font-weight: 300;
	}
	p {
		margin: 0;
		color: #fff; 
		white-space: nowrap;
	}
	p strong {
		font-weight: normal;
	}
	p strong.red,
	.up span {
		color: $alert-color !important;
	}
	p strong.green,
	.down span {
		color: $success-color !important;
	}
	* {
		font-size: 12px;
		line-height: 22px;
	}
}