.reactieformulier {
	fieldset {
		background-color: #fafafa;
		padding: 1rem;
		textarea {
			background-color: #fff;
		}
	}
}
.reactie {
	border: $border-dark;
	padding: .5 * $column-gutter;
	margin: 0 0  .5 * $column-gutter 0;
	.meta {
		font-size: 0.8rem;
		margin-bottom: 0.5rem;
	}
	p:last-of-type {
		margin-bottom: 0;
	}
}