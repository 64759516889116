span[class*='flag'] {
	background-size: 24px 24px;
	background-position: center center;
	background-repeat: no-repeat;
	display: inline-block;
	height: 24px;
	width: 24px;
	vertical-align: middle;
}
	.flag_nl { background-image: url(../images/icons/flags/nl.png);}
	.flag_en { background-image: url(../images/icons/flags/en.png);}
	.flag_cn { background-image: url(../images/icons/flags/cn.png);}
	.flag_id { background-image: url(../images/icons/flags/id.png);}
	@media#{$retina} {
		.flag_nl { background-image: url(../images/icons/flags/nl-x2.png);}
		.flag_en { background-image: url(../images/icons/flags/en-x2.png);}
		.flag_cn { background-image: url(../images/icons/flags/cn-x2.png);}
		.flag_id { background-image: url(../images/icons/flags/id-x2.png);}
	}
.top-bar {
	.top-bar-section .has-dropdown {
		&:hover > a { background-color: $topbar-dropdown-link-bg !important; }
		> a {
			padding-right: ($topbar-height / 8) !important;
			&:after { display: none; }
			&:hover { background-color: $topbar-dropdown-link-bg !important; }
		}
		.dropdown li a {
			line-height: 3rem;
			text-transform: none;
		}
		.dropdown li.active a {
			background-color: darken($topbar-dropdown-link-bg, 10%) !important;
		}
	}
	.top-bar-section .dropdown li label {
		padding-bottom: 0.5rem;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		cursor: default;
	}
	
	.left > li {
		position: relative;
		&+ li {
			margin-left: 1em;
		}
		&:before {
			content: ' ';
			display: block;
			height: 1px;
			width: 40px;
			position: absolute;
			margin-left: 12px;
			top: 15px;
		}
		&:hover:before {
			display: none;
		}
		&.home {
			&:before { background-color: $color-home; }
			&.open:not(:hover) > a, &.open > a:not(:hover) { color: $color-home ; }
			&.active:not(:hover) > a, &.active > a:not(:hover) { color: $color-home ; }
		}
		&.news {
			&:before { background-color: $color-news; }
			&.open:not(:hover) > a, &.open > a:not(:hover) { color: $color-news ; }
		}
		&.projects {
			&:before { background-color: $color-projects; }
			&.open:not(:hover) > a, &.open > a:not(:hover) { color: $color-projects ; }
		}
		&.finance {
			&:before { background-color: $color-finance; }
			&.open:not(:hover) > a, &.open > a:not(:hover) { color: $color-finance ; }
		}
		&.business {
			&:before { background-color: $color-business; }
			&.open:not(:hover) > a, &.open > a:not(:hover) { color: $color-business ; }
		}
		&.culture {
			&:before { background-color: $color-culture; }
			&.open:not(:hover) > a, &.open > a:not(:hover) { color: $color-culture ; }
		}
		&.aboutus {
			&:before { background-color: $color-aboutus; }
			&.open:not(:hover) > a, &.open > a:not(:hover) { color: $color-aboutus ; }
		}
		
		&.left > .open,
		&.left > :hover,
		&.left > .open {
			> a {
				color: $topbar-link-color-active;
			}
		}	
	}
	
	transition: all 800ms;
	.left, .nav-utility, .logo {
		transition: all 800ms;
	}
	
	.left {
		position: absolute;
		left: 200px;
	}
	&:not(.dense) {
		border: none;
		.left {
			position: absolute;
			left: 0px;
		}
		
		.logo {
			opacity: 0;
		}
	}
	&.dense {
		ul.left > li {			
			// push dropdown links down to align under the colored horizontal line
			&:before {
				top: 25px;
			}
			> a {
			  	line-height: 2 !important;
			  	padding-top: 2rem !important;
			  	padding-bottom: 1rem !important;
			}			
		}			
	}
	.user-nav-horizontal {
		position: relative;
		z-index: 999;
		li,
		a:hover {
			background: none transparent !important;
			color: $primary-color !important;
		}
	}
	
	.top-bar-section ul li > a {
		font-family: $font-family-heading;
		font-size: 1rem;
		font-weight: 300; 
	}
}

.user-nav-horizontal {
	.logged-in-as { 
		a {
			color: $color-home !important;
		}
		span {
			font-size: rem-calc(14); 
			padding-right: 4px;
		}
	}
}

.logo-white-150 { max-width: 150px; }
	

.top-bar-container.pos-scroll {
	box-shadow: 0 5px 5px 0 rgba(0,0,0,0.4);
	.has-dropdown,
	.has-dropdown > a {
		max-height: 74px;
	}
}


.show-for-dense-only,
.dense .hide-for-dense { 
	display: none !important;
}
	.dense .show-for-dense-only {
		display: block !important;
	}


.langswitch-horizontal {
	padding-right: 10px;
	
	ul, li, a {
		float: left;
		margin: 0;
	}
	li {
		list-style: none;
	}
	a {
		margin: 27px 0 0 10px;
	}
}

#top-pre-scroll {
	height: 70px;
	//background: #f5f5f5;
	//border-bottom: 1px solid #ccc;
	transition: opacity 800ms;
	overflow: hidden;
	
	.logo img {
		margin-top: 32px;
	}
}

.top-bar-section ul.nav-utility > li {
	&+li {
		position: relative;
		&:before {
			background-color: rgba(0,0,0,.1);
			content: ' ';
			display: block;
			height: 34px;
			position: absolute;
			top: 50%;
			margin-top: -17px;
			width: 1px;
		}
	}
	> a {
		padding: 0 10px !important;
	}
}
	.dropdown-search-form {
		background-color: $color-dark;
		
		width: 100%;
	}

.tab-bar {
	border-bottom: 1px solid rgba(255,255,255,0.1);
	section.left {
		padding-left: ($column-gutter / 2)
	}
	.right-small.search {
		right: 3rem;
		text-align: center;
		a {
			color: #fff;
		}
	}
	.menu-icon span:after {
		box-shadow: 0 0 0 1px #fff, 0 7px 0 1px #fff, 0 14px 0 1px #fff;
	}
}


// crumbs
.crumb-container {
	clear: both;
	margin-bottom: -43px;
	position: relative;
	z-index: 8;
	.hier {
		opacity: .7;
	}
}
.crumbpath ul {
	margin: 0;
	li:before {
		margin: 0 4px;
	}
}
.crumbpath *,
.crumbpath *:before {
	color: #fff;
}
	

.subnav {
	.level1 {
		padding: 0;
		margin: 0;
	}
	.level2, .level3 {
		li { 
			font-size: rem-calc(13);
			line-height: 1.2;
			margin-top: rem-calc(9);
		}
	}
	.open {
		
	}
	.hier > a {
		color: $color-dark;
	}
	li {	
		font-size: rem-calc(14);
		list-style: none;
	}
	a {
		padding: 0.5rem 0;
	}
}
	.page-news { .subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover { color: $color-news; } }
	.page-projects { .subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover { color: $color-projects; } }
	.page-finance { .subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover { color: $color-finance; } }
	.page-business { .subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover { color: $color-business; } }
	.page-culture { .subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover { color: $color-culture; } }
	.page-aboutus { .subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover { color: $color-aboutus; } }

.linklist {
	label {
		margin-top: 0;
	}
	&.linklist-labels {
		li {
			padding-left: 20px;
			position: relative;
		}
		a {
			color: $color-dark;
			&:hover {
				color: $primary-color;
			}
		}
		.label {
			position: absolute;
			left: 0;
			top: rem-calc(5px);
			padding: 3px;
		}
	}
	.date {
		color: #878787;
		display: block;
		font-size: rem-calc(13);
		margin-top: 0.2rem;
	}
}
.inline-list > li:first-child {
	margin-left: 0;
}

.linklist,
.subnav > ul {
	> li {
		font-size: rem-calc(14);
		line-height: 1.2;
		&+ li {
			margin-top: rem-calc(16);
		}
	}
}
/*
.f-topbar-fixed {
	padding-top: 0 !important;
}
*/
ul.off-canvas-list li a {
	font-weight: 400;
}

@media #{$screen} and (max-width:#{upper-bound($medium-range)}) {
	.f-topbar-fixed {
		padding-top: 0 !important;
	}
	.top-bar-container.fixed {
		position: relative;
	}
	.top-pre-scroll {
		display: none !important;
	}
	.f-dropdown.dropdown-search-form {
		top: 44px !important;
	} 
		
	// fixed tabbar 
	.tab-bar {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 99999;
	}
	
	.main-section,
	.off-canvas-wrap, 
	.off-canvas-wrap .inner-wrap {
		height: 100%;
		width: 100%;
	}
	.main-section {
		height: 100%;
		overflow-y: scroll; /* has to be scroll, not auto */
	  	-webkit-overflow-scrolling: touch;
	}
	
	.maincontainer {
		padding-top: $tabbar-height;
	    top: 0;
	    bottom: 0;
	    position: absolute;
	    left: 0;
	    right: 0;
	}

}
@media#{$medium-up} {
	.contain-to-grid.fixed {
		border-bottom: 1px solid #464646;
	}
}
@media#{$large-up} {
	.top-bar .name {
		margin-right: 1rem;
	}
	.right-off-canvas-menu {
		display: none;
	}
}
