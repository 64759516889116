.poll {
	border: 1px solid #dfdfdf;
	
	//margin-right: $column-gutter;
	
	fieldset {
		margin: 0;
		
		label {
			margin-bottom: 0 !important;
			
			span {
				font-size: 1rem;
				padding-right: 4em;
			}
			
		}
		
		input.button {
			margin-top: 13px;
		}
	}
	
	.poll-legend {
		//font-size: 1.2em; 
		font-weight: normal; 
		list-style: none inside; 
		margin: 0;
		padding: 0;
		line-height: 1.5;
		
		li {
			position: relative;
			padding-left: 1.5em;
			padding-right: 4em;
			
			span {
				display: block; 
				position: absolute;
				
				&.color {
					height: 0.8em; 
    				left: 0;
					margin-right: 1em; 
				    top: 5px;
					width: 0.8em; 
				}
				
				&.percentage {
					right: 0;
					top: 0;
				}
			}
		}
		
	}
	
	.chartwrapper {
		margin-top: 1em;
		position: relative;
		
		.chart {
			min-height: 50px;
			padding: 0;
		}
		.center {
			position: absolute;
		    top: 50%;
		    width: 100%;
		    
		    p {
		    	font-size: 4em;
		    	font-weight: bold;
		    	line-height: .1em;
		    	text-align: center;
		    	display: none;
		    }
		}
	}
	
	h2 {
		text-transform: none;
	}
	
	p {
		&.total {
			//font-weight: bold;
			margin: 1em 0 0 0;
		}
	}
}