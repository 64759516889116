.pagefooter {
	background-color: lighten($color-dark, 15%);
	* {
		color: #ffffff;
	}
	ul, li {
		margin: 0;
		padding: 0;
	}
	li {
		list-style: none;
		a {
			font-weight: 300;
		}
	}
	.inline-list {
		li {
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	section .column.aboutus {
		a:hover { color: lighten($color-aboutus, 20%); }
	}
	.heading-h2 {
		font-weight: 300;
	}
}

.site-overview {
	.heading-h2 {
		font-size: 1rem;
	}
	.heading-h2:before {
		content: ' ';
		display: block;
		height: 1px;
		margin: 0 0 10px 0;
		width: 40px;
	}
	.news {
		a:hover { color: lighten($color-news, 20%); }
		.heading-h2:before { background-color: $color-news; }
	}
	.projects {
		a:hover { color: lighten($color-projects, 20%); }
		.heading-h2:before { background-color: $color-projects; }
	}
	.finance {
		a:hover { color: lighten($color-finance, 20%); }
		.heading-h2:before { background-color: $color-finance; }
	}
	.business {
		a:hover { color: lighten($color-business, 20%); }
		.heading-h2:before { background-color: $color-business; }
	}
	.culture {
		a:hover { color: lighten($color-culture, 20%); }
		.heading-h2:before { background-color: $color-culture; }
	}
}

.list-social {
	.ir {
		background-size: 24px 24px;
		display: block;
		height: 24px;
		width: 24px;
	}
}

.icon-facebook { background-image: url(../images/icons/facebook.png); }

.icon-twitter { background-image: url(../images/icons/twitter.png); }

.icon-googleplus { background-image: url(../images/icons/googleplus.png); }

.subfooter {
	background-color: $color-dark;
	padding: rem-calc(20px 0);
	ul {
		display: flex;
		justify-content: space-between;
	}
	li {
		font-size: rem-calc(12);
	}
	li * {
		color: rgba(255, 255, 255, 0.6);
	}
	a:hover {
		color: rgba(255, 255, 255, 1);
	}
}

@media#{$small-only} {
	.pagefooter section .column {
		float: none;
		width: 100%;
		& + .column {
			margin-top: $column-gutter;
		}
	}
	.subfooter ul {
		display: block;
	}
}

@media#{$large-up} {
	.site-overview .column { width: auto; }
	// even spread with flexbox
	.flexbox {
		.site-overview .padded {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			.column {
				padding: 0;
				&:first-child { margin-left: -54px; }
				&:last-child { margin-right: -54px; }
			}
		}
	}
	.pagefooter .newsletter {
		text-align: right
	}
}

@media#{$retina} {
	.icon-facebook { background-image: url(../images/icons/facebook-x2.png); }
	.icon-twitter { background-image: url(../images/icons/twitter-x2.png); }
	.icon-googleplus { background-image: url(../images/icons/googleplus-x2.png); }
}

