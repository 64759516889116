form .error .errorMessage {
  @include form-error-message;
}

input[type='submit'] {
  margin-bottom: 0;
}

// login
.page_login .pageheader {
  min-height: calc(100vh - 98px);
  fieldset {
    background: #fff;
  }
}

.loginoptions {
  font-size: rem-calc(12);
  margin-bottom: 0;
  li {
    font-weight: 400;
  }
}

.poll {
  @extend .panel;
  fieldset {
    padding: 0;
    .radiobuttonlistfield {
      display: block;
      padding-bottom: 1em;
      .radiobuttonlist {
        margin-bottom: 15px;
        & > span {
          & > label {
            margin-bottom: 0.8em;
            input {
              float: left;
              margin: 4px 10px 0 0;
            }
            span {
              display: block;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

form.search {
  background: #fafafa;
  border: 1px solid #eee;
  margin: 0 0 1.5rem 0;
  padding: 0;
  width: 100%;
  fieldset {
    border: none;
    margin: 0;
    padding: 1rem;
    & + fieldset {
      padding-top: 0;
    }
  }
  label {
    display: none;
    padding: 6px 8px 4px 0;
  }
  .inputcontainer {
    width: 66%;
  }
  fieldset:first-child .row {
    padding-right: 106px;
    position: relative;
  }
  .buttons {
    position: absolute;
    right: 0;
    top: 0;
    width: 106px;
    input {
      display: inline-block;
      margin: 0;
      width: 100%;
    }
  }
  input[type=text], input[type=search] {
    background: #fff;
    border-bottom-width: 5px;
    border-right: none;
    height: rem-calc(41);
    margin-bottom: 0;
    width: 100%;
  }
  input[type=reset] {
    background-color: #ccc;
    border-color: #909090;
    color: #666;
    cursor: pointer;
    margin-left: 5px;
    padding-left: 15px;
    padding-right: 15px;
    &:hover {
      color: #000;
    }
  }
  &.pre-scroll {
    background: none;
    border: none;
    margin: 0 20px 0 0;
    width: auto;
    fieldset {
      margin-top: 13px;
      padding: 15px;
      .row {
        padding-right: 32px;
      }
      input {
        background-color: rgba(255, 255, 255, 0.3);
        border: none;
        color: #fff;
        height: 24px;
        padding: 2px 6px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.4);
        }
      }
      button.small {
        background-color: rgba(255, 255, 255, 0.3);
        border: none;
        color: #8C8C8C;
        font-size: 0.2em;
        padding: 2px;
      }
    }
    .buttons {
      width: 32px;
    }
  }
}

.search {
  input[type=submit], input[type=reset] {
    line-height: rem-calc(19);
  }
}

input[type=submit], input[type=reset] {
  &.loading {
    background-image: url(../images/ajax-loader-5abee2.gif);
    background-position: center center;
    background-repeat: no-repeat;
    color: transparent;
  }
}

.f-dropdown.dropdown-search-form {
  background: none;
  border: none;
  &:before, &:after {
    display: none;
  }
  .search {
    border: none;
    margin: 0;
    input {
      margin: 0;
    }
  }
}
.humanityfield {
  input, .captchafresh {
    margin-bottom: 0;
  }
  .problem {
    margin-bottom: .5rem;
  }
  .description {
    display: none;
  }
  input[type=text] {
    float: left;
    width: 60px;
  }
  .captchafresh {
    float: right;
    padding-top: .33rem;
    a:hover {
      text-decoration: underline;
    }
  }
  .errorMessage {
    clear: both;
    display: inline-block;
    width: 100%; /* Voor IE9 */
  }
}

@media#{$large-up} {
  .dropdown-search-form {
    position: fixed;
    width: 100%;
  }
  .pos-scroll .dropdown-search-form {
    top: $topbar-height !important;
  }
  .top-bar-container:not(.pos-scroll) {
    .dropdown-search-form {
      display: none;
    }
  }
}

@media#{$small-only} {
  .page_login .pageheader {
    height: auto;
    min-height: 0;
  }
  .loginoptions {
    padding: 15px 0 0 0;
  }
  .dropdown-search-form {
    top: 43px !important;
  }
}